/* after changing this file run 'npm run webapp:build' */

/***************************
put Sass variables here:
eg $input-color: red;
****************************/
// Override Boostrap variables
@import 'bootstrap-variables';
// Import Bootstrap source files from node_modules
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap-vue/src/index.scss';

/* jhipster-needle-scss-add-vendor JHipster will add new css style */

.flex {
  display: flex;
}
